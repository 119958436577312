<template>
  <div class="sidebar">
    <!-- <router-link v-for="route in filteredRoutes" :key="route.name" :to="{ name: route.name }">
      {{ route.meta.title }}
    </router-link> -->
    <!-- <router-link :to="{ name: 'productList' }" class="sidebar-item" :class="{ active: isSidebarItemActive('productList') }">
      商品管理
    </router-link>
    <router-link :to="{ name: 'orderList' }" class="sidebar-item" :class="{ active: isSidebarItemActive('orderList') }">
      订单管理
    </router-link>
    <router-link :to="{ name: 'withdrawalList' }" class="sidebar-item" :class="{ active: isSidebarItemActive('withdrawalList') }">
      提现管理
    </router-link> -->
    <router-link
      v-for="(route, index) in sidebarRoutes"
      :key="index"
      :to="{ name: route.name }"
      :class="{ active: isSidebarItemActive(route.name) }"
    >
      {{ route.meta.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    sidebarRoutes() {
      return this.$router.options.routes.filter(
        (route) => route.meta && route.meta.title
      );
    },
  },
  mounted() {
    const sidebarItems = [];
    this.$router.options.routes.forEach((route) => {
      if (route.meta && route.meta.title) {
        sidebarItems.push(route.meta.title);
      }
    });
    console.log(sidebarItems);
  },
  methods: {
    isSidebarItemActive(routeName) {
      return this.$route.name === routeName;
    },
  },
};
</script>

<style scoped>
.sidebar {
  height: 100%;
  border-right: 1px solid #ccc;
}

.sidebar a {
  display: block;
  padding: 15px;
  text-align: center;
  color:#333;
  text-decoration: none;
}

.sidebar a:hover {
  background-color: #f5f7fa;
}

.sidebar .router-link-exact-active {
  color: red;
}
</style>
