<template>
  <div>
    <el-container>
      <el-header></el-header>
      <el-container style="height: 100vh;">
        <el-aside style="width: 200px;">
          <AsideBar />
        </el-aside>
        <el-main>
          <router-view /></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import AsideBar from "./components/AsideBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "layout",
  components: {
    AsideBar,
    Breadcrumb,
  },
};
</script>

<style scoped>
.el-header {
  background-color: #b3c0d1;
}

.el-aside {
  color: #333;
  text-align: center;
}

.el-main {
  color: #333;
  text-align: center;
}

</style>
