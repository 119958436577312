import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout";

Vue.use(VueRouter);

// const routes = [
//     {
//       path: "/",
//       component: Layout,
//       children: [
//         {
//           path: "product",
//           name: "productList",
//           component: () => import("@/views/product"),
//           meta: { title: "商品管理" },
//           children: [
//             {
//               path: "addProduct",
//               name: "addProduct",
//               component: () => import("@/views/product/addProduct"),
//               meta: { title: "SKU管理" },
//             },
//           ],
//         },
//         {
//           path: "/order",
//           name: "orderList",
//           component: () => import("@/views/order"),
//           meta: { title: "订单管理" },
//           children: [
//             {
//               path: "AfterSales",
//               name: "AfterSales",
//               component: () => import("@/views/order/AfterSales"),
//               meta: { title: "处理售后" },
//             },
//             {
//               path: "ManualShipping",
//               name: "ManualShipping",
//               component: () => import("@/views/order/ManualShipping"),
//               meta: { title: "手动发货" },
//             },
//           ],
//         },
//         {
//           path: "/withdrawal",
//           name: "withdrawalList",
//           component: () => import("@/views/withdrawal"),
//           meta: { title: "提现管理" },
//         },
//       // {
//       //   path: "/product/addProduct",
//       //   name: "addProduct",
//       //   component: () => import("@/views/product/addProduct"),
//       //   meta: { title: "SKU管理" },
//       // },
//       // {
//       //   path: "/order/AfterSales",
//       //   name: "AfterSales",
//       //   component: () => import("@/views/order/AfterSales"),
//       //   meta: { title: "处理售后" },
//       // },
//       // {
//       //   path: "/order/ManualShipping",
//       //   name: "ManualShipping",
//       //   component: () => import("@/views/order/ManualShipping"),
//       //   meta: { title: "手动发货" },
//       // },
//     ],
//   },
// ];

const routes = [
  {
    path: "/",
    redirect: "/product",
  },
  {
    path: "/product",
    name: "productList",
    component: Layout,
    meta: { title: "商品管理" },
    children: [
      {
        path: "",
        name: "productList",
        component: () => import("@/views/product"),
        meta: { title: "商品管理" },
      },
      {
        path: "addProduct",
        name: "addProduct",
        component: () => import("@/views/product/addProduct"),
        meta: { title: "SKU管理" },
      },
    ],
  },
  {
    path: "/order",
    name: "orderList",
    component: Layout,
    meta: { title: "订单管理" },
    children: [
      {
        path: "",
        name: "orderList",
        component: () => import("@/views/order"),
        meta: { title: "订单管理" },
      },
      {
        path: "AfterSales",
        name: "AfterSales",
        component: () => import("@/views/order/AfterSales"),
        meta: { title: "处理售后" },
      },
      {
        path: "ManualShipping",
        name: "ManualShipping",
        component: () => import("@/views/order/ManualShipping"),
        meta: { title: "手动发货" },
      },
    ],
  },
  {
    path: "/withdrawal",
    name: "withdrawalList",
    component: Layout,
    meta: { title: "提现管理" },
    children: [
      {
        path: "",
        name: "withdrawalList",
        component: () => import("@/views/withdrawal"),
        meta: { title: "提现管理" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

export default router;
