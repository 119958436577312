<template>
  <div class="breadcrumb">
    <router-link v-for="(crumb, index) in breadcrumbs" :key="index" :to="crumb.to">
      {{ crumb.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    breadcrumbs() {
      const crumbs = [];
      let route = this.$route;

      while (route) {
        if (route.meta && route.meta.title) {
          crumbs.unshift({ label: route.meta.title, to: route.path });
        }
        route = route.parent;
      }

      return crumbs;
    }
  }
};
</script>

<style>
.breadcrumb {
  background-color: #f0f0f0;
  padding: 10px;
}

.breadcrumb > * {
  margin-right: 5px;
  text-decoration: none;
  color: #333;
}

.breadcrumb > *:last-child {
  font-weight: bold;
}
</style>
